/**
* AdminRoutes.ts (abstractuser) *

* Copyright © 2020 InstaMaterial GmbH - All Rights Reserved. *

* Unauthorized copying of this file, via any medium is strictly prohibited.
* This file and all it's contents are proprietary and confidential. *

* Maintained by Sai Charan K, 2020 
* @file AdminRoutes.ts
* @author Sai Charan K
* @copyright 2020 InstaMaterial GmbH. All rights reserved.
* @section License
*/

import Dashboard from '../Components/Admin/Dashboard/DashboardPage';
import UserManagement from '../Components/Admin/UserManagement/UserManagement';
import Settings from '../Components/Admin/Settings/SettingsPage';
import ApplicationManagement from '../Components/Admin/ApplicationManagement/ApplicationManagement';
import Logs from '../Components/Admin/Logs/Logs';
import RoleManagement from '../Components/Admin/RoleManagement/RoleManagement';
import TemplateEditor from '../Components/Admin/TemplateEditor/TemplatePage';
import Profile from '../Components/Admin/Profile/ProfilePage';
import CssEditor from '../Components/Admin/TemplateEditor/CssEditor';
import i18n from '../Services/I18n';
import NotFoundRedirect from '@abstract/abstractwebcommon-client/NotFound/NotFoundRedirect';
import { RouteName } from '../Utils/routesNames';
import { SharedCommomRouteName } from '@abstract/abstractwebcommon-client/utils/sharedRoutesNames';

/**
 * Get admin routes
 * @returns adminRoutes
 */
export const getAdminRoutes = () => {
  const adminRoutes = [
    {
      path: RouteName.adminDashboardRoute,
      Component: Dashboard,
      showHeading: false
    },
    {
      path: SharedCommomRouteName.adminUserManagementRoute,
      Component: UserManagement,
      showHeading: true,
      headingKey: i18n.t('/admin.sidebar.menu.usermanagement')
    },
    {
      path: RouteName.adminRoleManagementRoute,
      Component: RoleManagement,
      showHeading: true,
      headingKey: i18n.t('/admin.sidebar.menu.rolemanagement')
    },
    {
      path: RouteName.adminApplicationManagementRoute,
      Component: ApplicationManagement,
      showHeading: true,
      headingKey: i18n.t('/admin.sidebar.menu.applicationmanagement')
    },
    {
      path: RouteName.adminSettingsRoute,
      Component: Settings,
      showHeading: false
    },
    {
      path: RouteName.adminTemplateEditorRoute,
      Component: TemplateEditor,
      showHeading: true,
      headingKey: i18n.t('/admin.sidebar.menu.template-editor')
    },
    {
      path: RouteName.adminCSSEditorRoute,
      Component: CssEditor,
      showHeading: true,
      headingKey: i18n.t('/admin.sidebar.menu.csseditor')
    },
    {
      path: RouteName.adminProfileRoute,
      Component: Profile,
      showHeading: true,
      headingKey: i18n.t('/admin.sidebar.menu.profile')
    },
    {
      path: RouteName.adminLogsRoute,
      Component: Logs,
      showHeading: true,
      headingKey: i18n.t('/admin.sidebar.menu.logs')
    },
    {
      Component: NotFoundRedirect
    }
  ];

  return adminRoutes;
};
