/**
* LogApi.ts (abstractuser) *

* Copyright © 2020 InstaMaterial GmbH - All Rights Reserved. *

* Unauthorized copying of this file, via any medium is strictly prohibited.
* This file and all it's contents are proprietary and confidential. *

* Maintained by James Ugbanu, 2022
* @file LogApi.ts
* @author Sai Charan K
* @copyright 2020 InstaMaterial GmbH. All rights reserved.
* @section License
*/

import { BASE_API_URL } from '../Config';
import { paginationRequest } from '@abstract/abstractwebcommon-client/Pagination/paginationRequest';
import {
  IAPIEntityResponse,
  IAPIErrorData,
  PaginatedAPIEntityResponse
} from '@abstract/abstractwebcommon-shared/interfaces/api';
import { ILog } from '@abstract/abstractwebcommon-shared/interfaces/user/log';
import { downloadFile } from '@abstract/abstractwebcommon-client/utils/downloadFile';
import { HttpClient } from '@abstract/abstractwebcommon-client/utils/HttpClient';
import { CreateErrorLog } from '@abstract/abstractwebcommon-shared/utils/CreateErrorLog';

/**
 * Create log
 * @param payload Payload to send with the request
 * @returns Promise
 */
export const createLogApi = (payload: CreateErrorLog): Promise<IAPIEntityResponse<ILog>> => {
  if (JSON.stringify(payload).includes('/userApplication/permission/validate')) {
    payload.error = `${payload.error} + client paths: previousURL: "${document.referrer}", currentURL: "${window.location.href}"`;
  }
  return httpClient.post('/logs/create', payload, null, false, false);
};

const httpClient = new HttpClient(BASE_API_URL, createLogApi);

/**
 * EncodeURI search term
 * @param payload Payload to send with the request
 * @returns String
 */
const handleSearchTermEncodeURI = (payload: Record<string, any>) =>
  `?searchTerm=${encodeURIComponent(payload.searchTerm ? payload.searchTerm : '')}&`;

/**
 * Gets system logs
 * @param payload Payload to send with the request
 * @returns Promise
 */
export const getLogsApi = (
  payload: Record<string, any>
): Promise<PaginatedAPIEntityResponse<ILog>> => {
  const url: string = paginationRequest(
    `/logs/all?searchTerm=${encodeURIComponent(payload.searchTerm ? payload.searchTerm : '')}&`,
    payload
  );
  return httpClient.get(url);
};
/**
 * Export logs to a .csv file
 * @param payload Payload to send with the request
 * @returns Promise
 */
export const exportLogsAPI = (
  payload: Record<string, any>
): Promise<
  | void
  | IAPIErrorData
  | {
      error: string;
    }
> => {
  const url: string = paginationRequest(
    `${BASE_API_URL}/logs/export${handleSearchTermEncodeURI(payload)}`,
    payload
  );
  return downloadFile(url, 'GET', 'logs.csv');
};
