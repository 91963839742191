/**
* PermissionPage.tsx (abstractuser) *

* Copyright © 2022 InstaMaterial GmbH - All Rights Reserved. *

* Unauthorized copying of this file, via any medium is strictly prohibited.
* This file and all it's contents are proprietary and confidential. *

* Maintained by Rafael Rodrigues, 2022 
* @file PermissionPage.tsx
* @author Rafael Rodrigues
* @copyright 2022 InstaMaterial GmbH. All rights reserved.
* @section License
*/

import React, { useEffect, Dispatch } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';

import PermissionPageComponent from '@abstract/abstractwebcommon-client/PermissionPage/PermissionPage';
import {
  updateUserApplicationPermissionAction,
  IUserApplicationsState,
  getUserApplicationsState,
  validateUserPermissionAction
} from '../../Store/UserApplicationsSlice';
import {
  getAuthState,
  IAuthState,
  updateAccessTokenAction,
  loginAction
} from '../../Store/AuthSlice';
import StateLoader from '../../Store/StateLoader';
import { showToast } from '@abstract/abstractwebcommon-client/AlertToast/AlertToast';
import withErrorBoundary from '@abstract/abstractwebcommon-client/HOC/withErrorBoundary';
import { createLogApi } from '../../Services/LogApi';
import { LocalStorage } from '@abstract/abstractwebcommon-client/utils/sharedLocalStorage';
import { isStringEmptyOrNullOrUndefined } from '@abstract/abstractwebcommon-shared/utils/sharedFunctions';

/**
 * @interface IPermissionPageComponentProperties
 */
interface IPermissionPageComponentProperties {
  logoURL: string /** Brand logo URL to display */;
  description: string /** Application description */;
  applicationName: string /** Application name */;
  username: string /** Username informed into login page to be used across endpoints */;
  password: string /** Passoword informed into login page to be used across endpoints */;
  applicationUUID: string /** Application UUID to be used across endpoints end redirect logic */;
  verificationURL: string /** Verification URL to be used into redirect logic */;
  redirectURL: string /** Redirect URL to be used into redirect logic */;
  accessToken?: string /** Token used when accessing from an application different than root application */;
}

/**
 * Permission Page
 * @return JSX.Element
 */
const PermissionPage = (): JSX.Element => {
  const location = useLocation<IPermissionPageComponentProperties>();
  const { state } = location;
  const t: TFunction = useTranslation().t;

  const dispatch: Dispatch<any> = useDispatch();
  const userApplicationsState: IUserApplicationsState = useSelector(getUserApplicationsState);
  const authState: IAuthState = useSelector(getAuthState);

  const handleGrantUserPermission = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    const jwtToken: string | null = (state?.accessToken || LocalStorage.getXAuthToken()) ?? null;

    const payload =
      jwtToken != null
        ? {
            applicationUUID: state?.applicationUUID,
            token: jwtToken
          }
        : {
            username: state?.username,
            password: state?.password,
            applicationUUID: state?.applicationUUID
          };

    dispatch(updateUserApplicationPermissionAction(payload));
  };

  /**
   * Handle update permission error
   */
  useEffect(() => {
    if (userApplicationsState.statusCode) {
      showToast({
        severity: 'error',
        summary: t('I18N.error_messages.failed'),
        detail: t('I18N.error_messages.update_user_applications_failure')
      });
    }
  }, [userApplicationsState.statusCode]);

  /**
   * Handle login and URL redirect
   */
  useEffect(() => {
    if (
      userApplicationsState.applicationPermission?.isUserPermissionGranted &&
      !state?.accessToken &&
      !userApplicationsState.isLoading
    ) {
      dispatch(
        loginAction({
          username: state?.username,
          password: state?.password
        })
      );
    }

    if (
      Object.keys(userApplicationsState.applicationPermission).length === 0 &&
      state?.accessToken &&
      !userApplicationsState.isLoading
    ) {
      dispatch(
        validateUserPermissionAction({
          token: state?.accessToken,
          applicationUUID: state?.applicationUUID
        })
      );
    }

    if (
      authState.isAuthenticated &&
      userApplicationsState.applicationPermission?.isUserPermissionGranted &&
      !userApplicationsState.isLoading
    ) {
      const isTokenValid: boolean = StateLoader.isTokenValid(LocalStorage.getXAuthToken());
      if (isTokenValid) {
        dispatch(updateAccessTokenAction(LocalStorage.getXAuthToken()));
        if (state?.verificationURL) {
          const parameters: URLSearchParams = new URLSearchParams({
            token: LocalStorage.getXAuthToken(),
            app: state?.applicationUUID,
            themeMode: LocalStorage.getThemeMode(),
            languageSettingsMode: LocalStorage.getLanguageSettingsMode()
          }); /**< Query parameters */
          if (!isStringEmptyOrNullOrUndefined(state?.redirectURL)) {
            parameters.append('redirect_url', state?.redirectURL);
          }
          window.location.href = `${state?.verificationURL}?${parameters.toString()}`;
        }
      }
    }
  }, [userApplicationsState.applicationPermission?.isUserPermissionGranted, authState.accessToken]);

  return (
    <PermissionPageComponent
      logoURL={state?.logoURL}
      description={state?.description}
      applicationName={state?.applicationName}
      isLoading={userApplicationsState.isLoading}
      handleGrantPermission={(e: React.MouseEvent<HTMLButtonElement>) =>
        handleGrantUserPermission(e)
      }
    />
  );
};

export default withErrorBoundary(PermissionPage, createLogApi);
