/**
* ProfilePage.tsx (abstractuser) *

* Copyright © 2020 InstaMaterial GmbH - All Rights Reserved. *

* Unauthorized copying of this file, via any medium is strictly prohibited.
* This file and all it's contents are proprietary and confidential. *

* Maintained by Etienne Daher, 2020 
* @file ProfilePage.tsx
* @author Etienne Daher
* @copyright 2020 InstaMaterial GmbH. All rights reserved.
* @section License
*/

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TabPanel, TabView } from 'primereact/tabview';
import {
  getApplicationsAction,
  getProfileAction,
  getProfileState,
  IProfileState,
  profileActions,
  updatePasswordAction,
  updateProfileAction
} from '../../../Store/ProfileSlice';
import {
  deleteUserAction,
  getRolesAction,
  getUserState,
  IUserState,
  userActions
} from '../../../Store/UserSlice';
import { useTranslation } from 'react-i18next';
import { getAuthState, IAuthState, manualUpdateUser } from '../../../Store/AuthSlice';
import { Dispatch } from 'redux';
import { TFunction } from 'i18next';
import InstaImageUpload, {
  FILE_UPLOAD_ERROR
} from '@abstract/abstractwebcommon-client/InstaImageUpload';
import CropDialog from '@abstract/abstractwebcommon-client/CropDialog/CropDialog';
import ProfileHeader from '@abstract/abstractwebcommon-client/Profile/ProfileHeader';
import { PasswordComponent } from './PasswordComponent';
import HomeComponent from './HomeComponent';
import DialogWrapper from '@abstract/abstractwebcommon-client/DialogWrapper/DialogWrapper';
import BasicInformationComponent from './BasicInformationComponent';
import { IApplications } from '@abstract/abstractwebcommon-shared/interfaces/user/applications';
import { Button, Row } from 'react-bootstrap';
import {
  getSafeAppSettingsAction,
  getSettingsState,
  ISettingsState
} from '../../../Store/SettingsSlice';
import Address from './Address/Address';
import { IUser } from '@abstract/abstractwebcommon-shared/interfaces/user/user';
import { showToast } from '@abstract/abstractwebcommon-client/AlertToast/AlertToast';

import './ProfilePage.css';
import { asyncErrorHandler } from '@abstract/abstractwebcommon-shared/utils/AsyncErrorHandler';

export const ProfilePage = (): JSX.Element => {
  const profileState: IProfileState = useSelector(getProfileState);
  const userState: IUserState = useSelector(getUserState);
  const authState: IAuthState = useSelector(getAuthState);
  const settingState: ISettingsState = useSelector(getSettingsState);
  const dispatch: Dispatch<any> = useDispatch();

  const t: TFunction = useTranslation().t;

  const [profileLoaded, setIsProfileLoaded] = useState<boolean>(false);
  const [initiateDelete, setInitiateDelete] = useState<boolean>(false);
  const [isEmailUpdated, setIsEmailUpdated] = useState<boolean>(false);
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const [croppedImage, setCroppedImage] = useState<any>(null);
  const [isImageDeleteRequested, setImageDeleteRequested] = useState<boolean>(false);
  const [isUploadLinkActive, setUploadLinkActive] = useState<boolean>(false);
  // NOTE: `Any` is used for file type here because of this type is not known/can have various types.
  const [isFileChanged, setFileChanged] = useState<boolean>(false);
  const [uploadedFile, setUploadedFile] = useState<any>(null);

  const [loadRoles, setLoadRoles] = useState<boolean>(true);
  const [loadApplications, setLoadApplications] = useState<boolean>(true);

  const reloadRoles = async (selectedApplicationIDs: number[]): Promise<void> => {
    await asyncErrorHandler(
      dispatch(getRolesAction({ selectedApplicationIDs: selectedApplicationIDs }))
    );
    setLoadRoles(false);
  };

  useEffect(() => {
    dispatch(getSafeAppSettingsAction());
  }, [dispatch]);

  useEffect(() => {
    // on unmount
    return () => {
      dispatch(profileActions.reset());
      dispatch(userActions.reset());
    };
  }, [dispatch, setIsProfileLoaded]);

  useEffect(() => {
    if (!profileLoaded) {
      setIsProfileLoaded(true);
      dispatch(getProfileAction({}));
    }
  }, [profileLoaded, setIsProfileLoaded]);

  useEffect(() => {
    if (profileState.updateError !== null) {
      showToast({
        severity: 'error',
        summary: 'Failed',
        detail: profileState.updateError?.message || profileState.updateError
      });
    }

    if (
      (!isEmailUpdated && profileState.isUpdated) ||
      (isEmailUpdated && profileState.isUpdated && authState.isAdmin)
    ) {
      showToast({
        severity: 'success',
        summary: t('I18N.success_messages.profile_update_success'),
        detail: ''
      });
    } else if (isEmailUpdated && profileState.isUpdated && !authState.isAdmin) {
      showToast({
        severity: 'success',
        summary: t('I18N.success_messages.email_update_success'),
        detail: ''
      });
    }

    if (profileState.passwordUpdateError !== null) {
      showToast({
        severity: 'error',
        summary: 'Failed',
        detail: profileState.passwordUpdateError?.message || profileState.passwordUpdateError
      });
    }

    if (profileState.isPasswordUpdated) {
      showToast({
        severity: 'success',
        summary: t('I18N.success_messages.profile_update_success'),
        detail: ''
      });
    }
  }, [
    profileState.updateError,
    profileState.passwordUpdateError,
    profileState.isPasswordUpdated,
    profileState.isUpdated,
    authState.isEmailUpdated,
    dispatch
  ]);

  /// if profile is loaded and roles not loaded yet
  useEffect(() => {
    if (profileState.profile && profileState.profile.id && loadRoles && authState.isAdmin) {
      const selectedApplicationIDs = profileState.profile.applications.map(
        (eachApplication: IApplications) => eachApplication.id
      );
      reloadRoles(selectedApplicationIDs);
    }
  }, [profileState.profile]);

  /// load applications
  useEffect(() => {
    if (loadApplications) {
      dispatch(getApplicationsAction(authState.isAdmin));
      setLoadApplications(false);
    }
  }, [dispatch, loadApplications, setLoadApplications]);

  /// handle profile update submission
  const handleSubmitProfile = async (userData: any) => {
    const payload = userData;
    payload.type = 'profile';
    setIsEmailUpdated(payload.email ? true : false);
    delete payload.verify;
    const response: any = await asyncErrorHandler(dispatch(updateProfileAction(payload)));
    // wait for profile update and on success update auth state
    if (response && response.payload && response.payload.status === 200) {
      dispatch(manualUpdateUser(payload));
      setUploadLinkActive(false);
    }
  };

  /// User deletion handler.
  const handleUserDelete = (userUUIDs: string[]) => {
    setInitiateDelete(true);
    dispatch(deleteUserAction(userUUIDs[0]));
  };

  /// Check errors or success of user deletion.
  useEffect(() => {
    if (userState?.isDeleted && initiateDelete) {
      onDelete(true);
      setInitiateDelete(false);
    }
    if (userState.deleteError !== null && initiateDelete) {
      onDelete(false);
      setInitiateDelete(false);
    }
  }, [userState, initiateDelete, setInitiateDelete]);

  const onDelete = (isDeleted: boolean) => {
    if (isDeleted) {
      showToast({
        severity: 'success',
        summary: t('I18N.success_messages.profile_pic_updated')
      });
    } else {
      showToast({
        severity: 'error',
        summary: t('I18N.error_messages.profile_pic_update_failure')
      });
    }
    setIsProfileLoaded(false);
  };

  const handleSubmitPassword = (data: any) => {
    const payload: any = {
      type: 'password',
      oldPassword: data.oldPassword,
      password: data.password,
      passwordRepeat: data.passwordRepeat
    };
    dispatch(updatePasswordAction(payload));
  };

  /// Triggers on profile pic upload success. Sets the imageName to display image
  const onUpload = async (files: any[]): Promise<void> => {
    const file: any = files ? files[0] : null;
    setFileChanged(true);
    setUploadedFile(file);
    setImageDeleteRequested(false);
  };

  /// Dispatches deleteAPI to delete the profile picture
  const clearProfileImage = (): void => {
    setImageDeleteRequested(true);
    setUploadedFile({});
    setCroppedImage(null);
  };

  /// Error handler for the fileUpload component.
  const errorHandler = (error: string): void => {
    if (error === FILE_UPLOAD_ERROR.NO_FILE_UPLOADED) {
      showToast({ severity: 'error', summary: t('I18N.settings.no_image_uploaded') });
    }
    if (error === FILE_UPLOAD_ERROR.NOT_AN_IMAGE) {
      showToast({ severity: 'error', summary: t('I18N.settings.upload_valid_image') });
    }
  };

  /// Image crop handler
  const handleImageCropComplete = (image: any): void => {
    setCroppedImage(image);
    setUploadedFile(image);
  };

  /// Gets the fileUpload component.
  const getFileUpload = (): JSX.Element => {
    return (
      <InstaImageUpload
        showLegend={false}
        imageUrl={(profileState.profile && profileState.profile?.imageUrl) || ''}
        showDelete={true}
        deleteHandler={clearProfileImage}
        imgContainerClass={'px-3'}
        onChange={onUpload}
        croppedImage={croppedImage}
        showUploadBtn={false}
        errorHandler={errorHandler}
        imgClass={'imageUrl rounded'}
        altText={t('I18N.settings.logo_alt')}
        isPlainBtn={true}
        plainBtnLabel={t('I18N.user_profile.choose_profile_pic')}
      />
    );
  };

  /// Dispatch upload image action
  const uploadImageHandler = (): void => {
    if (uploadedFile !== null) {
      const profile: IUser = {
        ...profileState.profile
      };
      delete profile.id;
      delete profile.username;
      delete profile.email;
      if (isImageDeleteRequested) {
        handleSubmitProfile({ userUUID: profile.userUUID, imageName: '' });
      } else {
        handleSubmitProfile({
          userUUID: profile.userUUID,
          imageName: uploadedFile
        });
      }
      setUploadedFile(null);
    }
  };

  return (
    <>
      <ProfileHeader
        firstName={profileState && profileState.profile ? profileState.profile.firstName : ''}
        lastName={profileState && profileState.profile ? profileState.profile.lastName : ''}
        profilePictureURL={
          profileState && profileState.profile ? profileState.profile.imageUrl : ''
        }
        isLoading={!profileState.profile}
        onProfileChange={() => {
          setUploadLinkActive(true);
        }}
      />
      <div className="border-0">
        <div>
          <TabView activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)}>
            <TabPanel header={t('I18N.profile.home')}>
              <HomeComponent changeTabHandler={setActiveIndex} settingState={settingState} />
            </TabPanel>
            <TabPanel header={t('I18N.profile.basic_info')}>
              <BasicInformationComponent
                onUpdateUser={handleSubmitProfile}
                isLoading={profileState.isLoading}
                onDelete={onDelete}
                handleUserDelete={handleUserDelete}
                profileState={profileState}
                reloadRoles={reloadRoles}
              />
            </TabPanel>
            <TabPanel header={t('I18N.profile.security')}>
              <PasswordComponent
                handleSubmitPassword={handleSubmitPassword}
                isLoading={profileState.isLoading}
              />
            </TabPanel>
            <TabPanel header={t('I18N.profile.address')}>
              <Address />
            </TabPanel>
          </TabView>
        </div>
      </div>
      <DialogWrapper
        headerTitle={t('I18N.profile.upload_header')}
        isDialogVisible={isUploadLinkActive}
        onHide={() => setUploadLinkActive(false)}>
        <div className="bg-dark text-center px-4 py-2 mb-4 primary-border-radius">
          {getFileUpload()}
        </div>
        <Row className="justify-content-center">
          <Button
            variant="primary d-flex align-items-center"
            onClick={uploadImageHandler}
            disabled={!uploadedFile}>
            <i className="bi bi-check2-circle btn-icon"></i>
            {t('I18N.profile.upload_button')}
          </Button>
        </Row>
        <CropDialog
          isVisible={isFileChanged}
          setVisible={setFileChanged}
          file={uploadedFile}
          onImageCropComplete={handleImageCropComplete}
          isCircular={true}
          isCropOptionsVisible={false}
        />
      </DialogWrapper>
    </>
  );
};

export default ProfilePage;
