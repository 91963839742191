/**
* ActivateUser.tsx (abstractuser) *

* Copyright © 2020 InstaMaterial GmbH - All Rights Reserved. *

* Unauthorized copying of this file, via any medium is strictly prohibited.
* This file and all it's contents are proprietary and confidential. *

* Maintained by Sai Charan K, 2020 
* @file ActivateUser.tsx
* @author Sai Charan K
* @copyright 2020 InstaMaterial GmbH. All rights reserved.
* @section License
*/

import React, { Dispatch, useEffect, useState } from 'react';
import { Card } from 'primereact/card';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  activateUserAction,
  getAuthState,
  IAuthState,
  isVerificationLinkValidAction
} from '../../../Store/AuthSlice';
import Container from 'react-bootstrap/esm/Container';
import Row from 'react-bootstrap/esm/Row';
import Col from 'react-bootstrap/esm/Col';
import Loader from '@abstract/abstractwebcommon-client/Loader';
import { Alert } from 'react-bootstrap';
import { TFunction } from 'i18next';
import withErrorBoundary from '@abstract/abstractwebcommon-client/HOC/withErrorBoundary';
import { createLogApi } from '../../../Services/LogApi';
import queryString from 'query-string';
import { SharedCommomRouteName } from '@abstract/abstractwebcommon-client/utils/sharedRoutesNames';
import { showToast } from '@abstract/abstractwebcommon-client/AlertToast/AlertToast';

const ActivateUser = (): JSX.Element => {
  const history = useHistory();
  const search: string = useLocation().search;
  const parsedString: any = queryString.parse(search); /**< Query Parameters. */
  const userID: string = parsedString['userID']; /**< User ID. */
  const token: string = parsedString['token']; /**< Token. */
  const state: IAuthState = useSelector(getAuthState);
  const dispatch: Dispatch<any> = useDispatch();
  const translation: TFunction = useTranslation().t;
  const [showMessage, setShowMessage] = useState<boolean>(false);

  useEffect(() => {
    if (userID) {
      dispatch(isVerificationLinkValidAction({ userUUID: userID }));
      setShowMessage(true);
    }
  }, []);

  useEffect(() => {
    if (token && userID && state.isVerificationLinkValid) {
      dispatch(activateUserAction({ token: token, userID: userID }));
      setShowMessage(true);
    }
  }, [state.isVerificationLinkValid]);

  useEffect(() => {
    if (state.isUserAlreadyVerified) {
      showToast({
        severity: 'success',
        summary: translation('I18N.register.user_already_verified')
      });
      history.push('/user');
    }
  }, [state.isUserAlreadyVerified]);

  useEffect(() => {
    if (state.isUserActivated) {
      setTimeout(() => history.push({ pathname: SharedCommomRouteName.loginRoute }), 1000);
    }
  }, [state.isUserActivated, history]);

  const getMesssage = () => {
    if (showMessage && !state.isLoading && !state.isCheckingVerificationLinkValidity) {
      return (
        <Alert
          variant={state.isUserActivated && state.isVerificationLinkValid ? 'success' : 'warning'}>
          {state.isUserActivated
            ? translation('I18N.user_activation.user_activated')
            : `${
                state.activateUserError
                  ? state.activateUserError
                  : translation('I18N.user_activation.error')
              }`}
        </Alert>
      );
    }
    return <Loader />;
  };

  return (
    <Container className="activationContainer">
      <Row>
        <Col xs={12} className="mt-3">
          <Card
            className="rounded-corners-to-card-component keep-original-card-style keep-original-card-title-style"
            title={translation('I18N.user_activation.title')}>
            {getMesssage()}
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default withErrorBoundary(ActivateUser, createLogApi);
